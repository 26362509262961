<script>
import { formatToPrice } from "@/util/reusable";
import BasePriceInput from "./BasePriceInput";
import { XFormSelect } from "@/components/ui-components/form-select";
import { useShowPrice } from "@/composables/useShowPrice";
import { useShowPromo } from "@/composables/useShowPromo";
import { v3ServiceApi } from "@/services/v3/v3.service";

export default {
  name: "Calculator",
  setup() {
    return { ...useShowPrice(), ...useShowPromo() };
  },
  components: {
    // BaseSelect,
    XFormSelect,
    BasePriceInput,
  },
  props: {
    apartment: {
      type: Object,
      required: true,
    },
    hasApartment: {
      type: Boolean,
      default: true,
    },
    calculation: {
      type: Object,
      required: true,
    },
  },
  emits: ["calc-update"],
  data() {
    return {
      timeout: null,
    };
  },
  watch: {
    showPromo() {
      this.$emit("update-calc", {
        field: "type",
        value: "custom",
      });
    },
    currentInstallment: {
      handler() {
        this.upHillForPrint();
      },
    },
  },
  mounted() {},
  computed: {
    m2discount() {
      return +(
        (this.calculation.calcResult.discount_amount || 0) /
        this.apartment.plan.area
      ).toFixed(2);
    },
    discountOptions() {
      if (!this.calculation.calcResult) return [];
      let ds = [...this.calculation.calcResult.discounts]
        .sort((a, b) => a.prepay - b.prepay)
        .filter((d) => d.type !== "other");
      if (this.calculation.changes.type === "custom") {
        return ds
          .filter((d) => d.type !== "promo")
          .map((discount, index) => {
            let text = this.$t("apartments.view.variant");
            text += ` ${index + 1} - ${discount.prepay}%`;
            return {
              ...discount,
              text,
              value: discount,
              id: discount.id,
            };
          });
      }
      if (this.calculation.changes.type === "promo") {
        return ds
          .filter((d) => d.type === "promo")
          .map((discount, index) => {
            let text = this.$t("apartments.view.variant");
            text += " " + `( ${this.$t("promo.by_promo")} )`;
            text += ` ${index + 1} - ${discount.prepay}%`;
            return {
              ...discount,
              text,
              value: discount,
              id: discount.id,
            };
          });
      }
      return ds
        .filter((d) => d.type !== "promo" && d.prepay !== 100)
        .map((discount, index) => {
          let text = this.$t("apartments.view.variant");
          text += ` ${index + 1} - ${discount.prepay}%`;
          return {
            text,
            value: discount,
            id: discount.id,
          };
        });
    },
    discount() {
      return this.calculation.calcResult.discounts.find(
        (el) => el.id === this.calculation.changes.discount
      );
    },
    installment() {
      if (this.discount.installment_months.length === 0) return null;
      return this.discount.installment_months.find(
        (el) => el.id === this.calculation.changes.installment
      );
    },
    priceCalculations() {
      if (this.calculation.changes.type === "installment") {
        return this.installment.price_calculations;
      }
      return this.discount.price_calculations;
    },
    installmentOptions() {
      console.log("installment", this.discount);
      return this.discount.installment_months.map((el) => ({
        value: el.id,
        text: el.months + " " + this.$t("months"),
      }));
    },
    hasPromo() {
      if (!this.calculation.calcResult) return false;
      return this.calculation.calcResult.discounts.some(
        (el) => el.type === "promo"
      );
    },
    calcTypes() {
      const types = [
        {
          text: this.$t("flexible"),
          value: "custom",
        },
      ];
      if (this.apartment.object.is_installment_month) {
        types.push({
          text: this.$t("tariff"),
          value: "installment",
        });
      }

      if (this.showPromo && this.hasPromo)
        types.push({
          text: this.$t("promo.name"),
          value: "promo",
        });
      return types;
    },

    showMonthlyCalculation() {
      if (!this.discount) return false;
      return this.discount.prepay !== 100;
    },
  },
  methods: {
    lazyEmit(e) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("calc-update", e);
      }, 300);
    },
    pricePrettier: (price, decimalCount) => formatToPrice(price, decimalCount),
    changeCurrentInstallment(v) {
      if (v) this.currentInstallment = v;
    },
  },
};
</script>

<template>
  <div class="d-flex flex-wrap justify-content-between">
    <!--    INPUTS      -->
    <div class="w-100 inputs">
      <!--    PAYMENT OPTIONS       -->
      <div class="mb-3">
        <x-form-select
          :value="calculation.changes.type"
          :options="calcTypes"
          :placeholder="$t('plan_type')"
          value-field="value"
          @change="(e) => $emit('calc-update', { field: 'type', value: e })"
        />
      </div>
      <div>
        <x-form-select
          :value="calculation.changes.discount"
          :options="discountOptions"
          :placeholder="$t('payment_discount')"
          value-field="id"
          @change="(e) => $emit('calc-update', { field: 'discount', value: e })"
        />
      </div>
      <div class="mt-3">
        <x-form-select
          v-if="calculation.changes.type == 'installment'"
          :value="calculation.changes.installment"
          :options="installmentOptions"
          :placeholder="$t('Длительность рассрочки')"
          value-field="value"
          @change="
            (e) => $emit('calc-update', { field: 'installment', value: e })
          "
        />
      </div>
      <!--     INPUT MONTHLY PAYMENT       -->
      <div
        v-show="
          showMonthlyCalculation &&
          calculation.changes.type !== 'installment' &&
          calculation.changes.type !== 'promo'
        "
        class="calc_monthly"
      >
        <div class="placeholder font-weight-600">
          {{ $t("monthly_payment") }}
        </div>
        <div class="input d-flex justify-content-between">
          <!-- :value="calculation.changes.month" -->
          <input
            :value="discount.months"
            type="number"
            class="input-monthly-payment color-gray-600 w-100"
            :placeholder="$t('monthly_payment')"
            @input="
              (e) =>
                lazyEmit({
                  field: 'months',
                  value: +e.target.value,
                })
            "
          />
          <span v-if="false" class="d-block">{{ $t("monthly_payment") }}</span>
          <div class="font-inter color-gray-600 font-weight-600">
            {{ $t("month") }}
          </div>
        </div>
        <div class="square-price font-inter color-gray-600 font-weight-600">
          {{
            $t("by_price", {
              price: `${pricePrettier(priceCalculations.monthly, 2)}`,
            })
          }}
        </div>
      </div>

      <div
        class="d-flex justify-content-between align-items-center"
        style="column-gap: 0.5rem"
        v-if="showPrice"
      >
        <base-price-input
          ref="discount-per-square"
          class="discount-per-m2"
          :label="true"
          :currency="`${$t('ye')}`"
          :placeholder="$t('apartments.view.discount_per_m2')"
          :value="calculation.calcResult.discount_m2"
          :permission-change="true"
          @input="
            (e) =>
              $emit('calc-update', {
                field: 'm2_discount',
                value: e,
              })
          "
        />
        <base-price-input
          ref="all-discount-price"
          class="discount-per-m2"
          :label="true"
          :currency="`${$t('ye')}`"
          :placeholder="$t('apartments.view.discount_all')"
          :value="calculation.calcResult.discount_amount"
          :permission-change="true"
          @input="
            (e) =>
              $emit('calc-update', {
                field: 'total_discount',
                value: e,
              })
          "
        />
      </div>
    </div>

    <!--     OUTPUTS     -->
    <div class="w-100 outputs font-inter">
      <!--      Initial Price          -->
      <div class="d-flex justify-content-between">
        <span class="property d-block color-gray-400">
          {{ $t("starting_price") }}
        </span>
        <span class="price d-block color-gray-600">
          {{
            pricePrettier(calculation.calcResult.expensive_apartment_price, 2)
          }}
          {{ $t("ye") }}
        </span>
      </div>

      <!--      Price for meters square          -->
      <div
        v-if="!apartment.object.is_hide_m2_price && showPrice"
        class="d-flex justify-content-between"
      >
        <span class="property d-block color-gray-400">
          {{ $t("selling_price") }} m<sup>2</sup>
        </span>
        <span class="price d-block color-gray-600">
          {{ pricePrettier(priceCalculations.per_square_price, 2) }}
          {{ $t("ye") }}</span
        >
      </div>

      <div class="d-flex justify-content-between">
        <span class="property d-block color-gray-400">
          {{ $t("apartments.view.prepayment") }} {{ discount.prepay }}%
        </span>

        <span class="price d-block color-gray-600">
          {{ pricePrettier(priceCalculations.initial_payment, 2) }}
          {{ $t("ye") }}
        </span>
      </div>
      <!--       Month count          -->
      <div
        v-if="discount.type === 'promo'"
        class="d-flex justify-content-between"
      >
        <span class="property d-block color-gray-400">
          {{ $t("installment") }}
        </span>
        <span class="price d-block color-gray-600">
          {{ discount.months }} {{ $t("month_lowercase") }}
        </span>
      </div>

      <!--       Monthly Payment          -->
      <div v-if="discount.prepay < 100" class="d-flex justify-content-between">
        <span class="property d-block color-gray-400">
          {{ $t("monthly_pay") }}
        </span>
        <span class="price d-block color-gray-600">
          {{ pricePrettier(priceCalculations.monthly, 2) }} {{ $t("ye") }}
        </span>
      </div>

      <!--       Monthly Payment          -->
      <div v-if="discount.prepay < 100" class="d-flex justify-content-between">
        <span class="property d-block color-gray-400">
          {{ $t("Последняя") }}
        </span>
        <span class="price d-block color-gray-600">
          {{ pricePrettier(priceCalculations.last_month, 2) }} {{ $t("ye") }}
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="property d-block color-gray-400">
          {{ $t("total_discount") }}
        </span>
        <span class="price d-block color-gray-600">
          {{
            pricePrettier(
              calculation.calcResult.expensive_apartment_price -
                priceCalculations.transaction_price,
              2
            )
          }}
          {{ $t("ye") }}
        </span>
      </div>
      <!--      Total Percent Discount          -->
      <div class="d-flex justify-content-between">
        <span class="property d-block color-gray-400">
          {{ $t("total_discount_percent") }}
        </span>
        <span class="price d-block color-gray-600">
          {{
            (
              ((calculation.calcResult.expensive_apartment_price -
                priceCalculations.transaction_price) *
                100) /
              calculation.calcResult.expensive_apartment_price
            ).toFixed(2)
          }}

          %
        </span>
      </div>

      <!--      Total Price          -->
      <div class="d-flex justify-content-between">
        <span class="property d-block color-violet-600">{{ $t("total") }}</span>
        <span class="price d-block color-violet-600 total-price">
          {{ pricePrettier(priceCalculations.transaction_price, 2) }}
          {{ $t("ye") }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.inputs
  //margin-right: 2.25rem
  margin-bottom: 2.5rem

.outputs
  //margin-right: 2.25rem

  & > div
    margin-bottom: 2rem
    font-size: 18px
    font-weight: 600

    .total-price
      font-size: 24px

  .property
    font-size: 18px
    font-weight: 600

    sup
      color: var(--gray-400)

.calc_monthly
  background-color: var(--gray-100)
  border-radius: 1rem
  margin-top: 1.5rem

  .placeholder
    letter-spacing: 1px
    color: var(--gray-400)
    text-transform: uppercase
    line-height: 10px
    font-size: 0.6rem
    margin-bottom: 0.25rem
    padding-top: 0.75rem
    padding-left: 1.5rem

  .input
    padding: 0.25rem 1.25rem 0.75rem 0
    margin-left: 1.5rem

    &::placeholder
      padding: 0

    &-monthly-payment
      border: none
      background-color: transparent

  .square-price
    padding: 0.75rem 1.25rem
    background-color: var(--gray-200)
    border-top: 2px solid var(--gray-300)
    border-bottom-right-radius: 1rem
    border-bottom-left-radius: 1rem

.discount-per-m2
  border-radius: 2rem
  background-color: var(--gray-100)
  margin-top: 1.5rem
  width: 100%
  border: none
  padding: 0.75rem 1.25rem
</style>
