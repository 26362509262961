import { render, staticRenderFns } from "./BaseInput2.vue?vue&type=template&id=69ac4d82&scoped=true"
import script from "./BaseInput2.vue?vue&type=script&lang=js"
export * from "./BaseInput2.vue?vue&type=script&lang=js"
import style0 from "./BaseInput2.vue?vue&type=style&index=0&id=69ac4d82&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69ac4d82",
  null
  
)

export default component.exports