<script>
import api from "@/services/api";
import BaseLoading from "@/components/Reusable/BaseLoading";
import BaseButton from "@/components/Reusable/BaseButton";
import BasePrintIcon from "@/components/icons/BasePrintIcon";
import Reserve from "@/components/Dashboard/Apartment/Components/Reserve";
// import Calculator from '@/components/Objects/view/elements/Calculator2'
import Calculator from "@/components/Objects/view/elements/checkout-new/Calculator";
import BaseArrowLeftIcon from "@/components/icons/BaseArrowLeftIcon";
import BaseArrowRightIcon from "@/components/icons/BaseArrowRightIcon";
import { mapGetters, mapMutations } from "vuex";
import PrimaryTabItem from "@/components/Objects/view/elements/checkout-new/PrimaryTabItem";
import { directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import PdfTemplate from "@/components/PdfTemplateNew";
import { formatToPrice } from "@/util/reusable";
import CheckoutPermission from "@/permission/checkout";

import { v3ServiceApi } from "@/services/v3/v3.service";
import { v4ServiceApi } from "@/services/v4/v4.service";

export default {
  name: "ApartmentView",
  components: {
    // BookedBlock,
    Reserve,
    BaseButton,
    Calculator,
    PdfTemplate,
    BaseLoading,
    // PromoSection,
    BasePrintIcon,
    // PeculiarityBox,
    PrimaryTabItem,
    BaseArrowLeftIcon,
    BaseArrowRightIcon,
  },
  directives: {
    swiper: directive,
  },
  props: {
    appLoading: {
      type: Boolean,
      default: false,
    },
    apartment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultCalcResult: null,
      calculation: {
        changes: {
          type: "custom",
          discount: null,
          installment: null,
          months: null,
          total_discount: null,
          m2_discount: null,
        },
        calcResult: null,
      },
      isDownloading: false,
      imgDataUrl: "",
      /* SLIDER OPTION */
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        direction: "horizontal",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        paginationClickable: true,
        draggable: true,
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      /* SLIDER END */
      selectedOption: null,
      discountPerM2: null,
      // apartment: {},
      // appLoading: false,
      showReservationModal: false,
      printCalc: {},
      pdfVisible: false,
      bookPermission: CheckoutPermission.getCheckoutPermission("book"),
      checkoutPermission: CheckoutPermission.getCheckoutPermission("checkout"),
      checkoutRootPermission: CheckoutPermission.getCheckoutPermission("root"),
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
      userPermission: "getPermission",
      reserveClient: "getReserveClient",
    }),
    hasApartment() {
      return Object.keys(this.apartment).length > 0;
    },

    permission() {
      const context = {
        cancelReserve: false,
        reserve: false,
        continueOrder: false,
        order: false,
        contract: false,
      };

      if (!this.hasApartment) return context;

      const { apartment, me } = this;
      const { order } = apartment;
      // const {checkout} = userPermission
      const forSale = apartment.is_sold;
      const authorityUser = order?.user?.id === me?.user?.id;
      // const rootContract = userPermission?.checkout?.root
      // const isMainRole = me?.role?.id === 1
      const isStatusBooked = order.status === "booked";
      const isStatusAvailable = order.status === "available";
      const isStatusHold = order.status === "hold";
      const isStatusSold = order.status === "sold";
      const isStatusClosed = order.status === "closed";
      const isStatusContract = order.status === "contract";

      const permissionCancelReserve =
        isStatusBooked && (authorityUser || this.checkoutRootPermission);
      const permissionReserve =
        forSale && isStatusAvailable && this.bookPermission;

      const permissionContract = () => {
        const permissionOne = this.checkoutPermission && authorityUser;
        return (
          (isStatusSold || isStatusContract || isStatusClosed) &&
          (permissionOne || this.checkoutRootPermission)
        );
      };

      const permissionOrder = () => {
        const permissionOne =
          isStatusAvailable &&
          (authorityUser ||
            this.checkoutPermission ||
            this.checkoutRootPermission);
        return forSale && permissionOne;
      };
      const permissionContinueOrder = () => {
        const permissionOne =
          isStatusHold &&
          (authorityUser ||
            this.checkoutRootPermission ||
            this.checkoutPermission);
        const permissionTwo =
          isStatusBooked && authorityUser && this.checkoutPermission;
        return permissionOne || permissionTwo;
      };

      const effectContext = (property) => {
        context[property] = true;
      };

      permissionCancelReserve && effectContext("cancelReserve");
      permissionReserve && effectContext("reserve");
      permissionOrder() && effectContext("order");
      permissionContinueOrder() && effectContext("continueOrder");
      permissionContract() && effectContext("contract");

      return context;
    },
    price() {
      return `${formatToPrice(this.apartment.prices.price, 2)} ${this.$t(
        "ye"
      )}`;
    },
    squareMetrePrice() {
      return `${formatToPrice(this.apartment.prices.price_m2, 2)} ${this.$t(
        "ye"
      )}`;
    },
    statusSold() {
      return this.apartment.order.status === "sold";
    },
  },

  watch: {
    apartment: {
      async handler() {
        if (this.apartment?.plan?.images) {
          this.toDataUrl(this.apartment.plan.images[0])
            .then((url) => this.toDataUrl(url))
            .then((d) => (this.imgDataUrl = d))
            .catch((er) => console.log("error", er));
        }
        this.calculation.changes = {
          type: "custom",
          discount: null,
          installment: null,
        };
        const res = await v3ServiceApi.orders.calculatePrices({
          uuid: this.apartment.id,
        });
        const calcResult = res.data.result;
        this.calculation.changes.discount = calcResult.discounts
          .filter((d) => d.type !== "promo" && d.type !== "other")
          .sort((a, b) => a.prepay - b.prepay)[0].id;
        this.defaultCalcResult = calcResult;
        this.calculation.calcResult = calcResult;
      },
      immediate: true,
    },
  },

  mounted() {
    Fancybox.bind("[data-fancybox]");
  },
  methods: {
    async updateCalculaton() {
      const payload = {
        uuid: this.apartment.id,
      };
      if (this.calculation.changes.months) {
        payload.months = this.calculation.changes.months;
      }
      if (this.calculation.changes.total_discount) {
        payload.total_discount = this.calculation.changes.total_discount;
      }
      if (this.calculation.changes.m2_discount) {
        payload.m2_discount = this.calculation.changes.m2_discount;
      }

      const res = await v3ServiceApi.orders.calculatePrices(payload);
      const calcResult = res.data.result;
      this.calculation.calcResult = calcResult;
      if (this.calculation.changes.months) {
        const res = await v3ServiceApi.orders.calculatePrices({
          uuid: this.apartment.id,
          months: this.calculation.changes.months,
        });
        this.defaultCalcResult = res.data.result;
      }
    },
    handleCalcUpdate(e) {
      this.calculation.changes[e.field] = e.value;
      const discounts = this.getDiscounts();
      if (["installment", "type", "discount"].includes(e.field)) {
        this.calculation.changes.m2_discount = null;
        this.calculation.changes.total_discount = null;
        if (e.field === "type") {
          this.calculation.changes.discount = discounts[0]?.id;
          if (e.value === "installment") {
            this.calculation.changes.installment =
              discounts[0]?.installment_months?.[0]?.id;
          }
          return;
        }
        if (e.field === "discount") {
          this.calculation.changes.installment =
            discounts[0]?.installment_months?.[0]?.id;
          return;
        }
      }
      if (e.field === "total_discount") {
        this.calculation.changes.total_discount = e.value;
        this.calculation.changes.m2_discount = null;
      }
      if (e.field === "m2_discount") {
        this.calculation.changes.m2_discount = e.value;
        this.calculation.changes.total_discount = null;
      }
      this.updateCalculaton();
    },
    getDiscounts() {
      const discounts = this.calculation.calcResult.discounts;
      if (this.calculation.changes.type === "promo") {
        return discounts.filter((d) => d.type === "promo");
      }
      if (this.calculation.changes.type === "custom") {
        return discounts.filter((d) => d.type !== "promo");
      }
      if (this.calculation.changes.type === "installment") {
        return discounts.filter((d) => d.type !== "promo" && d.prepay !== 100);
      }
    },
    async toDataUrl(url) {
      // Convert to base64
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    ...mapMutations(["setCalculationProperties"]),
    printPdf() {
      this.pdfVisible = true;
      this.isDownloading = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 10);
    },
    completePrintingProcess() {
      this.pdfVisible = false;
      this.isDownloading = false;
    },

    printApartmentInformation() {
      window.print();
    },
    async orderApartment() {
      // eslint-disable-next-line vue/no-mutating-props
      this.appLoading = true;
      try {
        const data = {
          type: "apartment",
          apartment: {
            apartment_id: this.apartment.apartment_id,
          },
        };
        const res = await v4ServiceApi.orders.create(data);
        if (res.data.data) {
          const { object } = this.$route.params;
          await this.$router.push({
            name: "checkout-new",
            params: {
              id: res.data.data.id,
              object,
            },
          });
        }
      } catch (e) {
        this.toastedWithErrorCode(e);
      } finally {
        this.appLoading = false;
      }
    },
    async continueApartmentOrder() {
      const { object } = this.$route.params;
      await this.$router.push({
        name: "checkout-new",
        params: {
          object,
          id: this.apartment.order.order_id,
        },
      });
    },
    updateContent() {
      this.$emit("update-content");
    },
    async cancelReservation() {
      this.appLoading = true;
      await api.orders
        .fetchOrderClient(this.apartment.order.id)
        .then((response) => {
          const status = response.data;
          this.$swal({
            title: this.$t("sweetAlert.title"),
            text: this.$t("sweetAlert.text_cancel_reserve"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t("cancel"),
            confirmButtonText: this.$t("sweetAlert.yes_cancel_reserve"),
          }).then((result) => {
            if (result.value) {
              this.appLoading = true;
              api.orders
                .deactivateReserveOrders(client.id)
                .then((response) => {
                  this.toasted(response.data.message, "success");

                  this.$nextTick(() => {
                    this.$bvModal.hide("modal-view-reserved-client");
                  });

                  this.hideApartmentSidebar();
                  this.updateContent();

                  this.$swal(
                    this.$t("sweetAlert.canceled_reserve"),
                    "",
                    "success"
                  );
                })
                .catch((error) => {
                  this.toastedWithErrorCode(error);
                })
                .finally(() => {
                  this.appLoading = false;
                });
            }
          });
        })
        .catch((error) => {
          this.toastedWithErrorCode(error);
        })
        .finally(() => {
          this.appLoading = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <div v-if="!appLoading">
      <!--   MAIN   -->
      <div class="content-view">
        <div class="main__content">
          <div class="slider-content">
            <div v-swiper="swiperOption" class="swiper">
              <!--     MAIN CONTENT OF SLIDE       -->
              <div class="swiper-wrapper">
                <div
                  v-for="(image, index) in apartment.plan.images"
                  :key="index"
                  class="swiper-slide"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <img
                      v-if="image"
                      class="swiper-image"
                      :data-fancybox="image"
                      :src="image"
                      alt="img"
                    />
                    <img
                      v-else
                      class="swiper-image"
                      :src="require('@/assets/img/no-image.jpg')"
                      alt="img"
                    />
                  </div>
                </div>
              </div>

              <!--     DOTS PAGINATION       -->
              <div class="swiper-pagination" />

              <!--     BUTTON PREVIOUS       -->
              <div
                slot="button-prev"
                class="swiper-button-prev swiper-button d-flex justify-content-center align-items-center"
              >
                <base-arrow-left-icon />
              </div>

              <!--     BUTTON NEXT       -->
              <div
                slot="button-next"
                class="swiper-button-next swiper-button d-flex justify-content-center align-items-center"
              >
                <base-arrow-right-icon />
              </div>
            </div>
          </div>
          <PrimaryTabItem class="primary__information" :apartment="apartment" />
        </div>

        <div class="calculator w-100 d-flex flex-column">
          <div>
            <h4 class="calculator-title color-gray-600 font-craftworksans">
              {{ $t("type_payment") }}
            </h4>
            <calculator
              v-if="calculation.calcResult"
              :apartment="apartment"
              :has-apartment="hasApartment"
              :calculation="calculation"
              @calc-update="handleCalcUpdate"
            />
          </div>

          <div class="align-self-stretch d-flex justify-content-end">
            <!--   ACTIONS     -->
            <div class="action-block">
              <!--      CHECKOUT        -->
              <!--              v-if="permission.order"-->
              <base-button
                v-if="permission.order"
                :text="`${$t('apartments.list.confirm')}`"
                class="checkout__button violet-gradient"
                @click="orderApartment"
              />

              <!--      CONTINUE CHECKOUT        -->
              <base-button
                v-if="permission.continueOrder"
                :text="`${$t('continue_registration')}`"
                class="checkout__button violet-gradient"
                @click="continueApartmentOrder"
              />
              <!--     CONTRACT VIEW         -->
              <router-link
                v-if="permission.contract"
                :to="{
                  name: 'contracts-view-new',
                  params: { id: apartment.order.order_id },
                }"
              >
                <base-button
                  design="violet-gradient px-5"
                  :text="$t('apartments.list.contract')"
                />
              </router-link>
              <button
                id="print"
                :disabled="isDownloading"
                class="print__button bg-gray-100 d-flex justify-content-center align-items-center"
                @click="printPdf"
              >
                <base-loading v-if="isDownloading" :height="40" />
                <base-print-icon v-else :square="20" fill="#4B5563" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  LOADING    -->
    <base-loading v-if="appLoading" />
    <PdfTemplate
      v-if="pdfVisible"
      ref="html2Pdf"
      :img-data="imgDataUrl"
      :apartment="apartment"
      :calculation="calculation"
      :defaultCalc="defaultCalcResult"
      :me="me"
      @has-downloaded="completePrintingProcess"
    />
  </div>
</template>

<style lang="sass" scoped>
.content-view
  display: flex
  //justify-content: space-between
  gap: 4rem
  padding-bottom: 3rem

.booked-section
  position: relative
  padding-top: calc(3rem + 6px)
  padding-bottom: 3rem

  &::after
    content: ''
    position: absolute
    top: 0
    left: -3rem
    height: 6px
    background: var(--gray-200)
    width: calc(100% + 6rem)

::v-deep .main__content
  .slider__image
    object-fit: contain

::v-deep .slider-content
  margin: 1rem
  max-width: 42rem
  //margin-left: 1rem
  //margin-right: 1rem
  //margin-bottom: 1rem
  .swiper-pagination-bullets
    bottom: 0 !important

  .swiper-pagination-bullet
    width: 9px
    height: 10px

  .swiper-container
    display: flex
    align-items: center
    justify-content: center
    height: 24rem

    .swiper-slide
      cursor: grab
      display: flex
      justify-content: center
      align-items: center
      padding: 0 58px

      .swiper-image
        width: 100%
        max-height: 19rem
        object-fit: contain
    //height: 16rem

    .swiper-button
      width: 3rem
      height: 3rem
      border-radius: 50%
      background-color: var(--gray-100)

    .swiper-button-next::after,
    .swiper-button-prev::after
      content: none

  .swiper-pagination
    margin-top: 3rem

    &-bullets
      bottom: 1rem

    &-bullet
      width: 0.75rem
      height: 0.75rem
      margin-right: 0.3rem
      background-color: var(--gray-400)

      &-active
        background-color: var(--violet-400)

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type="number"]
  -moz-appearance: textfield

.main__content
  padding-left: 1rem
  padding-right: 1rem
  min-width: 30rem

.main__class
  background-color: white
  padding: 3rem
  min-height: 100vh
  color: var(--gray-600)

.primary__information
  max-width: 42rem

.calculator
  margin-top: 3.5rem
  margin-left: 1rem
  margin-right: 1.5rem
  max-width: 500px

  h2
    font-family: 'CraftworkSans', serif
    font-weight: 900
    font-size: 24px
    line-height: 28px
    color: #9CA3AF

  &-title
    font-family: 'CraftworkSans', serif
    font-weight: 900
    font-size: 24px
    line-height: 28px
    color: #9CA3AF !important
    margin-bottom: 1.5rem

  .inputs
    margin-right: 2.25rem
    margin-bottom: 2.5rem

  .outputs
    margin-right: 2.25rem

    & > div
      margin-bottom: 2rem
      font-size: 18px
      font-weight: 600

      .total-price
        font-size: 24px

::v-deep .monthly
  background-color: var(--gray-100)
  border-radius: 2rem
  margin-top: 1.5rem
  display: none

  .placeholder
    letter-spacing: 1px
    color: var(--gray-400)
    text-transform: uppercase
    line-height: 10px
    font-size: 0.6rem
    margin-bottom: 0.25rem
    padding-top: 0.75rem
    padding-left: 1.5rem

  .input
    padding: 0.25rem 1.25rem 0.75rem 0
    margin-left: 1.5rem

    &::placeholder
      padding: 0

    &-monthly-payment
      border: none
      background-color: transparent

  .square-price
    padding: 0.75rem 1.25rem
    background-color: var(--gray-200)
    border-top: 2px solid var(--gray-300)
    border-bottom-right-radius: 1rem
    border-bottom-left-radius: 1rem

.discount-per-m2
  border-radius: 2rem
  background-color: var(--gray-100)
  margin-top: 1.5rem
  width: 100%

.checkout__button
  padding: 17px 32.75px

.action-block
  display: flex
  flex-wrap: wrap
  margin-top: 1rem
  margin-bottom: .5rem
  gap: .5rem

  .checkout__button
    padding: 12.75px

.print__button,
.cancel__button,
.view__button
  outline: none
  border: none
  width: 3.5rem
  height: 3.5rem
  border-radius: 50%

.contract_view_btn
  height: 3.5rem
  border-radius: 2rem
  padding: 1rem 2rem
  background-color: var(--blue-600)

::v-deep .apartment__details
  &-title
    display: none

  &-row
    .property
      text-transform: none

.peculiarities
  margin-top: 48px

  h1
    font-family: 'CraftworkSans', serif
    font-weight: 900
    font-size: 18px
    line-height: 22px
    color: #9CA3AF

::v-deep .promo__section
  border: none
  border-bottom: 3px solid var(--gray-100)

::v-deep .promo__section:last-child
  border: none !important

.list-boxes
  gap: 16px

.price__section
  font-family: CraftworkSans, serif
  font-size: 1.5rem
  font-weight: 900
  margin: 1.5rem 0

.promo__section-subtitle
  text-transform: capitalize

  &-amount
    color: var(--gray-600) !important
    line-height: 28px

  &-square-amount
    color: var(--gray-500) !important
    font-size: 18px
    line-height: 22px

.no_promos
  font-family: 'Inter', serif
  font-weight: 600
  font-size: 18px
  line-height: 24px
  color: #9CA3AF
  margin-top: 24px
  margin-bottom: 48px

//@media only screen and (max-width: 1200px)
//  .content-view
//    flex-wrap: wrap
//    row-gap: 2rem
//    justify-content: center !important
//  .calculator
//    max-width: 640px
//  //.primary__information
//  //  width: 30rem
//  .price__section
//    font-size: 1.2rem
//
//    &-amount
//      line-height: 25px
//
//    &-square-amount
//      font-size: 15px
//      line-height: 18px
@media (max-width: 1350px)
  .content-view
    flex-wrap: wrap
  .calculator
    max-width: 42rem
</style>
