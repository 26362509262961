import Core from '@/services/core/index'
import { axiosBase } from '@/services/core/base'

class V4Service extends Core {
  constructor(
    axios = axiosBase({
      endpoint: '/api/v4',
    }),
  ) {
    super(axios, {
      headers: {
        'Accept-Language': '',
      },
    })
  }
  orders = {
    create: b => this.post('order/create', b),
    show: b => this.post('order/show', b),
    update: b => this.post('order/update', b),
    regenerate: b => this.post('order/generate-contract', b)
  }

  notificationTemplates = {
    create: b => this.post('notification/template/create', b),
    index: b => this.post('notification/template/index', b),
    update: b => this.post('notification/template/update', b),
    remove: b => this.post('notification/template/remove', b),
    checkStatus: b => this.post('notification/template/checkStatus', b),
    typesPeriodsList: b => this.post('notification/template/TypesPeriodsList', b),
  }

  notificationTasks = {
    show: b => this.post('notification/task/show', b),
    create: b => this.post('notification/task/create', b),
    index: b => this.post('notification/task/index', b),
    update: b => this.post('notification/task/update', b),
    remove: b => this.post('notification/task/remove', b),
  }
}


// eslint-disable-next-line import/prefer-default-export
export const v4ServiceApi = new V4Service()
